@import "~styles/mixins";
@import "~styles/variables";
.navWarp {
  background-color: $black-2;
  padding: 1rem !important ;
  max-height: 100vh;
  position: relative;
}
.navContainer {
  max-width: $centerContentWidth !important;
  padding:0!important;
}
.navItem {
  font-size: 18px;
  padding:0 0.4rem;
  &:hover {
    .navigation {
      color: $blue-2 !important;
    }
  }
  @media (min-width: 1000px) and (max-width: 1025px) {
    padding:0;
  }
  @media (max-width: 992px) {
    padding:15px 0;
    border-bottom: 1px solid hsla(0, 0%, 100%, .1);
    width: 100%;
   }
}
.navigation {
  color: $white !important;
  font-size: 1.1rem !important;
  font-weight: 500 !important;
  line-height: 130% !important;
  text-transform: none !important;
  &:hover {
    color: $blue-2 !important;
  }
  @media (min-width: 1000px) and (max-width: 1025px) {
    font-size: 0.8rem!important;
  }
  @media (max-width: 992px) {
    font-size: 14px!important;
    padding:0 15px!important;
   }
  
}
.menuBtn {
  border: none!important;
  &:focus{
    box-shadow: none!important;
  }
  .menuIcon {
    color:#fff !important;
  }
}

.fullLogo {
  width: 130px;
}
.navRight {
  display: flex;
  align-items: center;
}
.mobileCollapse{
  @media (max-width: 992px) {
   position: absolute;
   top:0;
   background-color: $black-2;
   left: 0;
   height: 100vh;
   animation: slide-in-from-left 0.5s ease-out forwards;
  }
}

@keyframes slide-in-from-left {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
.contactBtn{
  span{
    padding:10px 10px!important;
    @media (max-width: 420px) {
      font-size: 0.74rem;
      padding:10px 6px!important;
    }
  }
}
.mobileContactBtn {
  margin-right: 6px;
}
