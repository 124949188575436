@import '~styles/mixins';
@import '~styles/variables';
$--header-height: 140px;
$--safe-margin: 2rem;
$--text-align: initial;
$--padding-top: 96px;
$--padding-bottom: 96px;
$--padding-40: 40px;

.footer {
    padding-top: 30px;
    @include maxWidth1025{
        padding-left: 15px;
        padding-right: 15px;
    }
    width: 100%;
    background-color: $black-2;
    position: relative;
    .container {
        margin: auto;
        width: min(100%, $centerContentWidth);
    }

    .row {
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        display: flex;
        flex-wrap: wrap;
        margin-top: 0;
        margin-right: 1.5rem;
        margin-left: 1.5rem;
    }

    .col {
        padding-left: 15px;
        padding-right: 15px;
    }


    .block_text {
        p {
            font-size: 1rem;
            color: #fff;
            text-align: center;
        }
    }

    .footer__main {
        .form {
            margin-top: 39px;
            text-align: center;
        }

        .form_group {
            width: 100%;

            input {
                backdrop-filter: blur(2px);
                background: hsla(0, 0%, 100%, .1);
                border: 1px solid $blue;
                border-radius: 10px;
                box-shadow: none;
                color: #fff;
                font-size: 14px;
                font-weight: 300;
                margin: 0 auto;
                max-width: 540px;
                padding: 15px;
            }


        }

        .contentBtn {
            margin-top: 30px;
            span{
             padding: 10px 26px;
            }
         }
        

    }

    .footer__bottom {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 100px 0;
        @include maxWidth1025{
            padding: 40px 0;
        }

        .log {
            display: block;
            width: 100%;

            img {
                width: max(50%,150px);
            }
        }
        .footerRow{
            display: flex;
            align-items: center;
            justify-items: center;
            .copyright{
                padding-top: 20px;
                font-family: ReadexPro-Light,sans-serif;
                font-size: 0.9rem;
                clear: both;
            }
            .sectionWarp{
                display: flex;
                align-items: center;
                justify-content: center;
            }
            p{
                display: block;
                width: 100%;
                text-align: center;
                color: #fff; 
                font-size: 1rem;
            }
        }
        .navHref {
            margin-bottom: -30px;
            color: #fff;
            p{
                display: block;
                width: 100%;
                text-align: center;
            }
            ul {
                display: flex;
                margin-bottom: 16px;
                margin-left: 22px;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                a{
                    color: #fff;
                }
                a:hover{
                    color: $blue-3;
                }
            }

            li,
            ul {
                list-style-type: none;
                margin: 0;
                padding: 0;
            }

            .list {
                li {
                    margin: 0 16px;
                    display: block;
                    width: auto;
                    text-align: -webkit-match-parent;
                    unicode-bidi: isolate;
                    list-style-type: none;
                    text-align: center;
                    font-size: 1rem;
                }
            }
        }
    }
}
