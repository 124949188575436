@import '~styles/mixins';
@import '~styles/variables';
.btn{
    align-items: center;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    background: linear-gradient(
      89.96deg,
      hsla(0, 0%, 100%, 0.05) 0.03%,
      hsla(0, 0%, 100%, 0.008) 49.67%,
      hsla(0, 0%, 100%, 0.05) 99.96%
    );
    border: 1px solid hsla(0, 0%, 100%, 0.1);
    border-radius: 10px;
    display: inline-flex;
    font-weight: 700;
    justify-content: flex-start;
    padding: 5px;
    cursor: pointer;
    span {
      background: linear-gradient(264.28deg, $white -38.2%, $blue 103.12%);
      border-radius: 10px;
      color: #fff;
      padding: 18px 56px;
      transition: all 0.3s ease-in-out;
      font-size: 1rem;
    }
}
.sectionTitle{
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background: linear-gradient(
    89.96deg,
    hsla(0, 0%, 100%, 0.05) 0.03%,
    hsla(0, 0%, 100%, 0.008) 49.67%,
    hsla(0, 0%, 100%, 0.05) 99.96%
  );
  border: 1px solid hsla(0, 0%, 100%, 0.1);
  border-radius: 100px;
  display: inline-block;
  padding: 7px 14px;
  color: $blue;
  font-size: 15px;
  margin-bottom: 2.4rem;
}
.longSectionTitle{
  @include font-style(2rem, 500,$blue, center,$lineHeight: normal);
    margin-bottom: 2.4rem;
    @include maxWidth766{
        font-size: 1.4rem;
        margin-bottom: 1.8rem;
    }
}
.subDescription{
  font-family: ReadexPro-Medium, sans-serif;
  font-size: 1.4rem;
  color: #fff;
  margin-bottom: 2rem;
  @include maxWidth766{
    font-size: 1.2rem;
    margin-bottom: 1.6rem;
  }
}